import {
  Avatar,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  CircularProgress,
  LinearProgress,
} from "@material-ui/core";
import { FaFacebook, FaGoogle, FaLock } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { useAuth } from "../hooks/useAuth";
import styled from "styled-components";

import { brandOrange } from "./Colors";
import { useForm } from "react-hook-form";

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledAvatar = styled(Avatar)`
  margin-bottom: 20px;
  background-color: ${brandOrange};
`;

const StyledProgress = styled(CircularProgress)`
  margin-bottom: 20px;
`;

const SubmitButton = styled(Button)`
  margin-bottom: 10px;
`;

const PasswordField = styled(TextField)`
  margin-bottom: 20px;
`;

const StyledLink = styled(Link)`
  cursor: pointer;
`;

const LoginForm = ({ setIsPasswordResetMode }) => {
  const { doGoogleAuth, doFacebookAuth, doEmailPasswordAuth } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onLoginClick = ({ email, password }) => {
    doEmailPasswordAuth(email, password);
  };

  const onGoogleLoginClick = (e) => {
    e.preventDefault();
    doGoogleAuth();
  };

  const onFacebookLoginClick = (e) => {
    e.preventDefault();
    doFacebookAuth();
  };

  return (
    <>
      <Typography component="h1" variant="h5">
        Dalībnieku pieslēgšanās
      </Typography>
      <form>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="E-pasts"
          name="email"
          autoComplete="email"
          error={!!errors.email}
          helperText={!!errors.email && "Ievadi korektu e-pasta adresi!"}
          {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
        />
        <PasswordField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Parole"
          type="password"
          autoComplete="current-password"
          error={!!errors.password}
          helperText={
            !!errors.password && "Ievadi korektu paroli - vismaz 8 simboli!"
          }
          {...register("password", { required: true, minLength: 8 })}
        />
        <SubmitButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSubmit(onLoginClick)}
        >
          Ienākt / Reģistrēties
        </SubmitButton>
        <SubmitButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={onGoogleLoginClick}
        >
          <FaGoogle style={{ marginRight: "10px" }} />
          Ienākt ar Google
        </SubmitButton>
        <SubmitButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={onFacebookLoginClick}
        >
          <FaFacebook style={{ marginRight: "10px" }} />
          Ienākt ar Facebook
        </SubmitButton>
        <Grid container>
          <Grid item xs>
            <StyledLink
              onClick={() => setIsPasswordResetMode(true)}
              variant="body2"
            >
              Neatceros paroli
            </StyledLink>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

const PasswordResetForm = ({ setIsPasswordResetMode }) => {
  const { sendPasswordResetEmail } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const onResetClick = async ({ email }) => {
    try {
      await sendPasswordResetEmail(email);
      setIsPasswordResetMode(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Typography component="h1" variant="h5">
        Paroles atiestatīšana
      </Typography>
      <form>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="E-pasts"
          name="email"
          autoComplete="email"
          error={!!errors.email}
          helperText={!!errors.email && "Ievadi korektu e-pasta adresi!"}
          {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
        />

        <SubmitButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSubmit(onResetClick)}
        >
          Atiestatīt
        </SubmitButton>
        <Grid container>
          <Grid item xs>
            <StyledLink
              onClick={() => setIsPasswordResetMode(false)}
              variant="body2"
            >
              Atpakaļ
            </StyledLink>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

const Login = ({ nextUrl }) => {
  const [isPasswordResetMode, setIsPasswordResetMode] = useState(false);
  const { isBusy, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(nextUrl || "/profils");
    }
  }, [isAuthenticated]);

  if (isAuthenticated) {
    return <LinearProgress />;
  }

  return (
    <StyledContainer maxWidth="xs">
      {isBusy ? (
        <StyledProgress />
      ) : (
        <StyledAvatar>
          <FaLock />
        </StyledAvatar>
      )}
      {isPasswordResetMode ? (
        <PasswordResetForm setIsPasswordResetMode={setIsPasswordResetMode} />
      ) : (
        <LoginForm setIsPasswordResetMode={setIsPasswordResetMode} />
      )}
    </StyledContainer>
  );
};

export default Login;
