import * as React from "react";

import { Layout } from "../components/Layout";
import LoginComponent from "../components/Login";
import Seo from "../components/SEO";

const Login = () => {
  const nextUrl =
    typeof window === "object"
      ? new URLSearchParams(window.location.search).get("nextUrl")
      : null;

  return (
    <Layout>
      <Seo title="Pieslēgties" />
      <LoginComponent nextUrl={nextUrl} />
    </Layout>
  );
};

export default Login;
